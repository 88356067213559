export abstract class Memory{
    protected abstract keyInit(): string
    
    key: string = ""
    
    public save(){
        this.key = this.key || `${ this.keyInit() }:${ ( new Date() ).getTime() }`;
        localStorage.setItem( this.key, JSON.stringify( this ) );
        return this.key;
    }

    public delete(){
        localStorage.removeItem( this.key )
    }
}
