import { DateController } from "./DateController";
import Resource from "./Resource";
import { Store } from "./Store";
import Transaction from "./Transaction";

export class MainController{
    
    static transaction : Store< Transaction > 
    = new Store< Transaction >( DateController.getDate() + ":T", Transaction );

    static reosurce: Store< Resource > 
    = new Store< Resource >( "R", Resource );
    
    static clear( store : "resource" | "transaction" | "both" | "all" ){
        switch ( store ) {
            case "resource":
                this.reosurce.clear();
                break;
            case "transaction":
                this.transaction.clear();
                break;
            case "both":
                this.reosurce.clear();
                this.transaction.clear();
                break;
            case "all":
                this.reosurce.clear();
                this.transaction.clear();
                localStorage.clear();
                break;
            default:
                break;
        }
    }

    static reset( hardReset : boolean = false ){
        this.transaction.setStorageInit( ( hardReset ? DateController.reset() : DateController.getDate() ) + ":T" );
    }
}