import React from 'react';
import ResourceList from '../components/Resource/ResourceList';
import { Link } from '../Route';

const Home = () => {
    return (
        <div>

            <ResourceList />
            <p>
                <Link label = "Add New Resource" path = "#/resource/add" />
            </p>
            <hr />
            <h3>Add Transaction</h3>
            <div className="transaction-link">
                    <Link label = "Debit" path = "#/transaction/add/debit/" />
                    <Link label = "Credit" path = "#/transaction/add/credit/" />
                    <Link label = "Transfer" path = "#/transaction/add/transfer/" />
            </div>
            <p>
                <Link label = "View Transactions"   path = "#/transactions/view"/>
            </p>
            <hr />
            <p>
                <Link label = "Previous Records" path = "#/date/" />
            </p>
            <p>
                <Link label = "Save Todays Record"  path = "#/save" />
            </p>
        </div>
        
    );
}

export default Home;