import './App.css';
import Route from './Route';
import React from 'react';
import Router from './Router';
import ResourceList from './components/Resource/ResourceList';
import TransactionList from './components/Transaction/TransactionList';
import AddResource from './components/Resource/AddResource';
import AddTransaction from './components/Transaction/AddTransaction';
import SaveRecord from './screens/Save';
import Home from './screens/Home';
import { DateController } from './store/DateController';
import Previous from './screens/Previous';

function App() {

    const [date, setDate] = React.useState( '' );

    let callback = ( date ) => {
        setDate( date )
    }

    React.useEffect( () => {
        DateController.attach( callback );

        return () => {
            DateController.detach( callback );
        }
    }, [] );

    React.useEffect( () => {
        if ( window.location.hash === "" ) {
            window.location.hash = "#/";
        }
    }, [] );

    return (
            <div className = "content">
                <header className = "center" >Finance</header>
                <h3 className="center">
                    { date }
                </h3>
                <Router>
                    <Route path = "#/"
                        component = { Home } 
                        />
                    <Route path = "#/resource/add" 
                        component = { AddResource } 
                        />
                    <Route path = "#/resources/view" 
                        component = { ResourceList }
                        />
                    <Route path = "#/transaction/add/:type:/" 
                        component = { AddTransaction } 
                        />
                    <Route path = "#/transactions/view" 
                        component = { TransactionList } 
                        />
                    <Route path = "#/save" 
                        component = { SaveRecord } 
                        />
                    <Route path = "#/date/"
                        component = { Previous }
                        />
                </Router>
            </div>
    );
}

export default App;
