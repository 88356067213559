import React from 'react';
import TransactionList from '../components/Transaction/TransactionList';
import { DateController } from '../store/DateController';
import { MainController } from '../store/MainController';

export default function Previous(){
    const [date, setDate] = React.useState<string>( DateController.getDate() );

    let handleDate = ( e: React.ChangeEvent<HTMLInputElement> ) => {
        setDate( e.target.value.replaceAll("-", "/") );
    }

    React.useEffect( () => {
        MainController.transaction.setStorageInit( date + ":T" );

        return () => {
            MainController.reset();
        }
    }, [date] );
    
    return (
        <div>
            <form>
                <input type = "date" onChange = { handleDate } value = { date.replaceAll("/", "-") }/>
            </form>

            <TransactionList />
        </div>
    );
}