import React from "react";
import Resource from "../../store/Resource";
import { MainController } from "../../store/MainController";

export default function AddResource(){

    let handleSubmit = (e : React.FormEvent)=>{
        
        e.preventDefault();
        
        let form = e.target as HTMLFormElement;

        let res : string    = form['res'].value;
        let amount : number = parseInt( form['amount'].value );
        
        MainController.reosurce.add( new Resource( res, amount ) );

        window.location.hash = "#/";
    }

    
    return (
        <>
            <h3>Add New</h3>
            <form onSubmit = { handleSubmit }>
                <div>
                    <label>Name</label>
                    <input type="text" name="res"/>
                </div>
                <div>
                    <label>Amount</label>
                    <input type="number" name="amount" min="0" step="1"/>
                </div>
                <div>
                    <input type="submit" value="Add"/>
                </div>
            </form>
        </>
    );
}