import React, { ReactElement } from 'react';
import { Link } from './Route';

export default function Router(props : { children: ReactElement[] }){

    const [ link, setLink ] = React.useState( window.location.hash )
    React.useEffect( ()=>{
        window.onhashchange = () => {
            setLink( window.location.hash )
        }
    }, [])

    let params : { [x: string]: any } = { };

    const activeComponents = props.children.filter( ( value : { props: { path: string } }, index ) => {
        const path = value.props.path.split("/");
        const linkParts = link.split("/");

        if ( path.length === linkParts.length ) {
            let flag = true;
            
            for (let i = 0; i < path.length; i++) {
     
                if ( path[i].includes( ":" ) ) {
                    params[ path[i].replaceAll(":", "") ] = linkParts[i];
                } else {
                    flag = ( path[i] === linkParts[i] );
                    if ( !flag ){
                        return false
                    }       
                }
            }
            return flag;
        } else {
            return false;
        }
    } )

    if ( activeComponents.length === 0 ) {
        return <Link label = "Home" path = "#/" />;
    }
    
    const ActiveComponent = activeComponents[0].props.component;
    
    const componentProps = { ...ActiveComponent.props, ...params};
    return (
        <>
        { window.location.hash !== "#/" && <Link label = "Home" path = "#/" />}
        { <ActiveComponent { ...componentProps } /> }
        </>
    );
}