import { Memory } from './Memory';

export default class Resource extends Memory {

    constructor(public res: string = "", public amount: number = 0 ) { 
        super();
    }

    credit(amount: number){
        this.amount += amount;
        this.save();
    }

    debit(amount: number){
        this.amount -= amount;
        this.save();
    }

    protected keyInit(){
        return "R";
    }
}