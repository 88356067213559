// import React, { MouseEvent } from 'react';
import Resource from '../store/Resource';
import { MainController } from '../store/MainController';
import Transaction from '../store/Transaction';
import { DateController } from '../store/DateController';

const SaveRecord = () => {

    const resources : Resource[] = MainController.reosurce.getItems();

    const transactions : Transaction[] = MainController.transaction.getItems();

    return (
        <div>
            <div id = "save-text">
                <p>
                {
                    transactions.length > 0
                    ? transactions.map( ( item ) => {
                        return <span key = { item.key }>{ item.toString() }<br /></span>;
                    })
                    : "No transactions were made on " + DateController.getDate() + "."
                }
                </p>
                <p>===========</p>
                <p>End of Day</p>
                <p>
                {
                    resources.length > 0
                    ? resources.map( ( item ) => {
                        return <span key = { item.key }>{ item.res }: { item.amount }<br /></span>;
                    })
                    : "No resources are added."
                }
                </p>
                
            </div>
            {/* <textarea id = 'text' style = { { width: '0', height: '0' } }></textarea> */}
            
            {/* <button onClick = { copyText }>Copy Record</button> */}

            <button onClick = { () => { MainController.reset( true ) } }>Reset Date</button>
            <br />
            <button onClick = { () => { MainController.clear( "transaction" ) } }>Clear transactions</button>
            <br />
            <button onClick = { () => { MainController.clear( "resource" ) } }>Clear resources</button>
            <br />
            <button onClick = { () => { MainController.clear( "all" ); } }>Clear All Data</button>
        </div>
    );
}

// function copyText( e : MouseEvent< HTMLButtonElement > ){
//     let input = document.getElementById('save-text') as HTMLPreElement;
//     let input2 = document.getElementById('text') as HTMLTextAreaElement;

//     input2.value = input.innerText
    
//     input2.select();
//     document.execCommand("copy");

//     (e.target as HTMLButtonElement).innerText = "Copied";
// }
export default SaveRecord;