import React, { useEffect, useState } from "react";
import Resource from "../../store/Resource";
import { MainController } from "../../store/MainController";
import ResourceSingle from "./ResourceSingle";

export default function ResourceList() {

    const [ items, setItems ] = useState< Resource[] >( [] );

    let updateItems = ( items : Resource[] ) => {
        setItems( [ ...items ] );
    }

    useEffect( () => {
        MainController.reosurce.attach( updateItems );

        return () => {
            MainController.reosurce.detach( updateItems );
        }
    }, [] );

    return (
        <section>
            <h3>All resources</h3>
            {
                items.length > 0 
                ? items.map( ( { res, amount }, index ) => {
                    return (
                        <ResourceSingle res = { res } amount = { amount } key = { index } />
                    );
                })
                : <p>No resources are added.</p>
            }
        </section>
        
    );
}