export class DateController{
    private static currentDate : string = "";

    public static getDate() : string {
        if ( this.currentDate === "" ) {
            let dateStr = localStorage.getItem( "CurrentDate" );

            this.currentDate = dateStr ? dateStr : this.reset();
        }
        return this.currentDate;
    }

    public static reset() : string {
        let date = new Date;
        this.currentDate = date.getFullYear() + "/" + ( date.getMonth() + 1 )  + "/" + date.getDate();
        localStorage.setItem( "CurrentDate", this.currentDate );
        this.dispatch();
        return this.currentDate;
    }

    public static attach( listener : ( date: string ) => void ){
        listener( this.currentDate );
        this.listeners.push( listener );
    }

    public static detach( listener: ( date: string ) => void ){
        let index = this.listeners.indexOf( listener );
        this.listeners.splice( index, 1 )
    }

    public static dispatch(){
        this.listeners.forEach( element => element( this.currentDate ) )
    }

    private static listeners : ( ( date: string ) => void )[] = []
}