import { Memory } from "./Memory";

export type TransactionType = "Credit" | "Debit" | "Transfer";

export class Store<T extends Memory>{

    
    constructor( private storageInit: string, private type: { new(): T; } ){
        this.setStorageInit( storageInit );
    }

    private items: T[] = [];
    private itemsList: string[] = [];

    public getItems() {
        return this.items;
    }

    public setStorageInit( storageInit: string ){
        
        this.storageInit = storageInit;
        let itemsList = localStorage.getItem(`${storageInit}:list`);

        this.items = [];
        this.itemsList = [];

        if ( itemsList ) {
            this.itemsList = JSON.parse( itemsList );

            this.itemsList.forEach( ( itemKey, index ) => {
                let item = localStorage.getItem(`${itemKey}`);
                item && this.items.push( 
                    Object.assign( 
                        new this.type(),
                        JSON.parse( item ) )
                 );
            } );
        }
        
        this.dispatch();
    }

    public add( item: T ) {
        this.items.unshift( item );
        this.itemsList.unshift( item.save() );
        localStorage.setItem( `${this.storageInit}:list`, JSON.stringify( this.itemsList ) );
        this.dispatch();
    }

    public clear(){
        this.items.forEach( ( item ) => {
            item.delete();
        })
        localStorage.removeItem( `${this.storageInit}:list` );
        this.items = [];
        this.itemsList = [];
        this.dispatch();
    }

    private listeners: ( ( ( items: T[] ) => void ) )[] = []
    public attach( listener: (items: T[]) => void ) {
        listener( this.items );
        this.listeners.push( listener );
    }

    public detach( listener: ( ( items: T[] ) => void ) ) {
        let index = this.listeners.indexOf( listener );
        if ( index >= 0 ) {
            this.listeners.splice( index, 1 );
        }
    }

    private dispatch() {
        this.listeners.forEach( element => {
            element( this.items );
        } )
    }
}

// let r1 = new Resource( "Bank", 2000 );
// let r2 = new Resource( "Wallet", 3000 );
// resourceStore.add( r1 );
// resourceStore.add( r2 );

// transactionStore.add( Transaction.debit( r1, "Internet Bill", 300 ) );
// transactionStore.add( Transaction.credit( "Cash from Parents", r2 , 100 ) );
// transactionStore.add( Transaction.transfer( r2, r1, 1000 ) );
// transactionStore.add( Transaction.debit( r2, "Grocery Shopping", 500) );
// transactionStore.add( Transaction.debit( r1, "Online Course", 700) );