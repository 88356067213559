import React from 'react';
import Resource from '../../store/Resource';
import Transaction from '../../store/Transaction';

export default function TransactionSingle( { entry } : { entry: Transaction } ) {
    
    let values = {
        className: "type",
        from: "",
        to: "",
        amount: ""
    }

    switch ( entry.type ) {
        case "Credit":
            values = {
                from: entry.from as string,
                className : entry.type,
                to: (entry.to as Resource).res,
                amount: entry.amount.toString()
            }
        break;
        case "Debit":
            values = {
                from:  (entry.from as Resource).res,
                className: entry.type,
                to: (entry.to as string),
                amount: entry.amount.toString()
            }
        break;
        case "Transfer":
            values = {
                className: entry.type,
                from: (entry.from as Resource).res,
                to: (entry.to as Resource).res,
                amount: entry.amount.toString()
            }
        break;
    }

    const styles = { 
        display: 'flex', 
        padding: '10px',
    }
    
    return (
        <div style={ styles } className = { values.className + " transaction-single" }>
            <span style = { { width: '33%', textAlign: 'left' } } >{ values.from }</span>
            <span style = { { width: '33%', textAlign: 'center' } } >{ values.to }</span>
            <span style = { { width: '33%', textAlign: 'right' } } >{ values.amount }</span>
        </div>
    );
}