import React from 'react';
import { Link } from '../../Route';
import Resource from '../../store/Resource';
import { MainController } from '../../store/MainController';
import Transaction from '../../store/Transaction';

export default function AddTransaction( params : { type: string } ) {

    const items : Resource[] = MainController.reosurce.getItems();
    const type = ( params.type.charAt(0).toUpperCase() + params.type.substring(1) );

    // State variables
    const [   from,   setFrom ] = React.useState< number | string > ( type !== "Credit" ? 0 : "" );
    const [     to,     setTo ] = React.useState< number | string > ( type !== "Debit" ? ( type === "Transfer" ? 1 : 0 ) : "" );
    const [ amount, setAmount ] = React.useState< number >          ( 0 );
    
    let handleFrom = ( e: React.ChangeEvent< HTMLInputElement | HTMLSelectElement > ) => {
        setFrom( ( e.target.tagName === "INPUT" ) ? e.target.value : parseInt( e.target.value ) );

        type === "Transfer" && setTo( ( parseInt( e.target.value ) + 1 ) % items.length  );
    }

    let handleTo = ( e: React.ChangeEvent< HTMLInputElement | HTMLSelectElement > ) => {
        setTo( ( e.target.tagName === "INPUT" ) ? e.target.value : parseInt( e.target.value ) );
    }

    let handleAmount = ( e: React.ChangeEvent< HTMLInputElement > ) => {
        ! isNaN( parseInt( e.target.value ) ) && setAmount( parseInt( e.target.value ) );
    }

    let handleSubmit = ( e : React.FormEvent ) => {
        e.preventDefault();

        let transaction = 
        type === "Credit" 
        ? Transaction.credit( from as string, items[to as number], amount )
        : ( type === "Debit" ) 
        ? Transaction.debit( items[ from as number ], to as string, amount )
        : Transaction.transfer( items[ from as number ], items[ to as number ], amount)
        
        MainController.transaction.add( transaction );
        window.location.hash = "#/transactions/view";
    }

    if ( items.length === 0 ) {
        return <>
            <p>No resources added. Please add a resource to continue. </p>
            <p><Link label = "Add resource" path = "#/resource/add"/></p>
        </>;
    }

    if ( items.length < 2 && type === "Transfer" ) {
        return <> 
        <p>You have only one resource added. Transfer requires atleast two resources.
            If you're sending money to a friend or family, choose "Debit" option.</p>
        <p><Link label = "Add resource" path = "#/resource/add"/></p>
        </>;
    }
    
    return (
        <>
            <h3>Add New</h3>
            <form onSubmit = { handleSubmit } >
                <div>
                    <label>From</label>
                    {
                        type === "Credit" 
                        ? <input type = "text" name = "from" onChange = { handleFrom } value = { from } />
                        : <select name = "from" onChange = { handleFrom } value = { from }>
                            {
                                items.map( ( item, index ) => 
                                    <option key = { index } value = { index } >{ item.res }</option>
                                )
                            }
                        </select>
                    }
                </div>
                <div>
                    <label>To</label>
                    {
                        type === "Debit" 
                        ? <input type = "text" name = "to" onChange = { handleTo } value = { to }/>
                        : <select name = "to" onChange = { handleTo } value = { to }>
                            {
                                items.map( ( item, index ) => {
                                    return typeof from === "number" && from === index
                                    ? null
                                    : <option key = { index } value = { index } >{ item.res }</option>
                                }
                                )
                            }
                        </select>
                    }
                </div>
                <div>
                    <label>Amount</label>
                    <input type="number" name = "amount" min = "1" max = { typeof from === "number" ? items[from].amount : undefined } onChange = { handleAmount } value = { amount === 0 ? "" : amount } />
                </div>
                
                <div>
                    <input type="submit" onSubmit = { handleSubmit } />
                </div>
            </form>
        </>
    );
}
