import React, { ReactElement, ReactNode } from "react";

export default function Route( props: { label: string, component: ReactNode } ) : ReactElement {

    let title = props.label;
    let link = "#" + title.toLowerCase()
    return (
        <a href = { link } >{ title }</a>
    );

}

export function Link( { label, path } : { label: string, path: string } ){
    return (
        <a href = { path } >{ label }</a>
    );
}
