import Resource from "./Resource";
import { Memory } from "./Memory";
import { DateController } from "./DateController";

export default class Transaction extends Memory{
    type: "Debit" | "Credit" | "Transfer"
    from: string | Resource
    to: string | Resource 
    amount: number

    public constructor( type: "Debit" | "Credit" | "Transfer" = "Transfer", from: string | Resource = "", to: string | Resource = "", amount: number = 0 ){
        super();
        this.type = type;
        this.from = from;
        this.to = to;
        this.amount = amount;
    }

    public static debit( from: Resource, to: string, amount: number ) : Transaction{
        from.debit( amount );
        return new Transaction( "Debit", from, to, amount );
    } 

    public static credit( from: string, to: Resource, amount: number ) : Transaction{
        to.credit( amount );
        return new Transaction( "Credit", from, to, amount );
    } 

    public static transfer( from: Resource, to: Resource, amount: number ) : Transaction{
        from.debit( amount );
        to.credit( amount );
        return new Transaction( "Transfer", from, to, amount );
    }

    protected keyInit() : string {
        return DateController.getDate() + ":T";
    }

    public toString() : string {
        let str : string;

        switch( this.type ) {
            case "Credit":
                str = `${this.from}: +${this.amount}`;    
            break;
            case "Debit":
                str = `${this.to}: -${this.amount}`;
            break;
            case "Transfer":
                str = `Transfer: ${this.amount}`;
            break;
        }

        return str;
    }
}

class Debit{
    constructor( public from: Resource, public to: string, public amount: number ){
        from.debit( amount );
    }
}

class Credit{
    constructor( public from: string, public to: Resource, public amount: number ){
        to.credit( amount );
    }
}

class Transfer{
    constructor(public from: Resource, public to: Resource, public amount: number ){
        from.debit( amount );
        to.credit( amount );
    }
}