import React from 'react';
import { MainController } from '../../store/MainController';
import Transaction from '../../store/Transaction';
import TransactionSingle from './TransactionSingle';

export default function TransactionList(){

    const [ items, setItems ] = React.useState< Transaction[] >( [] );

    let updateItems = ( items : Transaction[] ) => {
        setItems( [ ...items ] );
    }

    React.useEffect( () => {
        MainController.transaction.attach( updateItems );

        return () => {
            MainController.transaction.detach( updateItems );
        }
    }, [] );

    return (
        <section>
            <h3>All transactions</h3>
            
            {
                items.length > 0
                ? items.map( 
                    ( transaction : Transaction, index: number ) => 
                    <TransactionSingle key = { index } entry = { transaction }/>
                )
                : <p>No transactions have been made.</p>
            }
        </section>
    );
}